import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        drawer: true,
        loading: false,
        mini: false,
        isDark: false,
        configurations: [],
    },
    mutations,
    actions,
    plugins: [createPersistedState()]
})

export default store
