<template>
  <v-container id="location-details" fluid>
    <snackbar/>
    <validation-observer ref="form">
      <v-form @submit.prevent="onSubmit">
        <v-card outlined>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="5">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                        <div class="overline mb-4">{{ i18n.promisesDelivery.items }}</div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-container>
                    <validation-provider rules="required" v-slot="{ errors }" :name="i18n.promisesDelivery.item.productsId">
                      <v-text-field
                        v-model="item"
                        :label="i18n.promisesDelivery.item.productsId"
                        :error-messages="errors"             
                        required
                      />
                    </validation-provider>
                  </v-container>
                </v-card>
              </v-col>
              <v-col cols="12" sm="7">
                <v-card outlined>
                  <v-list-item>
                    <v-list-item-content>
                        <div class="overline mb-4">{{ i18n.promisesDelivery.deliveryAddress }}</div>
                    </v-list-item-content>
                  </v-list-item>
                    <v-container>
                        <validation-provider rules="required" v-slot="{ errors }" :name="i18n.promisesDelivery.delivery.address_1">
                            <v-text-field
                                v-model="deliveryAddress.street_1"
                                :label="i18n.promisesDelivery.delivery.address_1"
                                :error-messages="errors"
                                required>
                            </v-text-field>
                        </validation-provider>
                        <validation-provider rules="required" v-slot="{ errors }" :name="i18n.promisesDelivery.delivery.postalCode">
                          <v-text-field
                            v-model="deliveryAddress.postal_code"
                            :label="i18n.promisesDelivery.delivery.postalCode"
                            :error-messages="errors"
                            required 
                          />
                        </validation-provider>

                        <v-row>
                          <v-col md=6>
                            <validation-provider rules="required" v-slot="{ errors }" :name="i18n.promisesDelivery.delivery.city">
                              <v-text-field
                                v-model="deliveryAddress.city"
                                :label="i18n.promisesDelivery.delivery.city"
                                :error-messages="errors"
                                required
                              />
                            </validation-provider>
                          </v-col>
                          
                          <v-col md=6>
                            <validation-provider rules="required" v-slot="{ errors }" :name="i18n.promisesDelivery.delivery.country">
                              <v-select
                                v-model="deliveryAddress.country_code"
                                :loading="countries.length === 0"
                                :items="countries"
                                item-text="name"
                                menu-props="auto"
                                item-value="code"
                                :label="i18n.promisesDelivery.delivery.country"
                                :error-messages="errors"
                                required
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                    </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-toolbar flat justify-content="center" height="auto">
          <v-row justify="center">
            <v-btn class="ma-2"
              :disabled="loading"
              :loading="loading"
              color="primary" outlined type='submit'
            >
              {{ i18n.promisesDelivery.simulate }}
            </v-btn>
          </v-row>
        </v-toolbar>
        </v-card>
      </v-form>
    </validation-observer>
    <PromisesDeliveryResult
        v-for="deliveryPromise, index in deliveryPromises"
        :key="deliveryPromise.date.unix()"
        :delivery-promises="deliveryPromise"
        :open-panel="index === 0"
    />
  </v-container>
</template>

<script>
import EventBus from "@/event-bus";
import PromisesDeliveryResult from '@/components/PromisesDeliveryResult'
import Snackbar from '@/components/Snackbar.vue'
import { Utils } from '@/mixins/Utils';
import { extend,ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import  moment  from 'moment'
import { Loader } from "@googlemaps/js-api-loader"
const i18n = require('../../lang')

extend("required", {
  ...required,
  message: (fieldName) => {
      return `${fieldName} is required`
    },
});

export default {
  name: "promisesDelivery",
  mixins: [Utils],
  components: {
    "validation-provider": ValidationProvider,
    "validation-observer": ValidationObserver,
    Snackbar,
    PromisesDeliveryResult
  },
  data() {
    return {
      i18n: i18n(this.$i18n.locale),
      valid: true,
      loading: false,
      countries: require('@/data/countries.json'),
      locations: [],
      item: '',
      deliveryAddress: {
        street_1: "",
        postal_code: "",
        city: "",
        country_code: 'FRA',
      },
      promisesDeliveryLoading: false,
      deliveryPromises: [],
      google: null
    };
  },
  async mounted(){
    this.loading = true
    if (this.$auth.accessToken) {
      await this.getLocations()
    }
    this.loading = false
    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
      version: "weekly",
    });
    this.google = await loader.load()

  },
  methods: {
    async onSubmit() {
      if (this.locations.length ===0) {
        await this.getLocations()
      }
      this.$refs.form.validate().then(async success => {
          if (success) {
            this.loading = true
            const items = this.item.replace(/\s/g, "").split(",").map(i => ({
              product_id: i,
              product_quantity: 1
            }))
            const address = `${this.deliveryAddress.street_1} ${this.deliveryAddress.postal_code} ${this.deliveryAddress.city} ${this.deliveryAddress.country_code}`

            let coordinates
            const geocoder = new this.google.maps.Geocoder();
            coordinates = await geocoder.geocode({'address': address})

            if (coordinates.results.length === 0) {
              EventBus.$emit('snackbar', {
                display: true,
                text: "Geocoding api error",
                color: "error"
              })
              this.promisesDeliveryLoading = true
              this.loading = false
              return
            }
            const lat = coordinates.results[0].geometry.location.lat()
            const lng = coordinates.results[0].geometry.location.lng()

            for(const location of this.locations) {
              location.distance = this.calculateDistance(lat, lng, location.address.latitude, location.address.longitude)
            }

            let pickupLocations = this.locations.sort((location1, location2) => {
              return location1.distance - location2.distance
            }).slice(0,3)
            pickupLocations = pickupLocations.filter(loc => loc.distance < 100)
            const pickupLocationIds = pickupLocations.map(l => l.location_id)

            const results = { date: moment(), deliveryAddress: { ...this.deliveryAddress}}
            EventBus.$emit('closePanels')
            this.deliveryPromises.unshift(results)
            //Home shipping
            const homeShippingRequestBody = this.buildMyInit({
              items,
              delivery: {
                type: 'home_delivery',
                shipping_address: this.deliveryAddress
              },
              stock_information: {
                channel: 'web'
              }
            })

            this.$Amplify.API.post('OmsApiUrl', '/v1/int/delivery-promises', homeShippingRequestBody).then(homeShippingResults => {
              this.$set(results, 'homeDeliveries',  homeShippingResults.delivery_promises)
              this.getCarbonFootprint(lat, lng, items.length).then(carbonFootprint => {
                for (const result of results.homeDeliveries) {
                  this.$set(result, 'carbonFootprint', carbonFootprint)
                }
              })
            }).catch( () =>  this.$set(results, 'homeDeliveries', {error: 'unavailable'}))

            //Relay point shipping
            const relayPointShippingRequestBody = this.buildMyInit({
              items,
              delivery: {
                type: 'relay_point_delivery',
                shipping_address: this.deliveryAddress
              },
              stock_information: {
                channel: 'web'
              }
            })

            this.$Amplify.API.post('OmsApiUrl', '/v1/int/delivery-promises', relayPointShippingRequestBody).then(relayPointShippingResults => {
              this.$set(results, 'relayPointDeliveries',  relayPointShippingResults.delivery_promises)
              this.getCarbonFootprint(lat, lng, items.length).then( carbonFootprint => {
                for (const result of results.relayPointDeliveries) {
                  this.$set(result, 'carbonFootprint', carbonFootprint + 0.3)
                }
              })
            }).catch(() =>  this.$set(results, 'relayPointDeliveries', {error: 'unavailable'}))


            //Store Shipping
            const storeShippingRequests = []
            for (const pickupLocationId of pickupLocationIds) {
              const storeShippingRequestBody = this.buildMyInit({
                items,
                delivery: {
                  type: 'store_delivery',
                  pickup_location_id: pickupLocationId
                },
                stock_information: {
                  channel: 'web'
                }
              })
              storeShippingRequests.push(
                  this.$Amplify.API.post('OmsApiUrl', '/v1/int/delivery-promises', storeShippingRequestBody)
              )
            }
            let storeShippingResponses = []

            storeShippingResponses = await Promise.allSettled(storeShippingRequests)

            const storeDeliveries = []
            let i = 0;
            for (const storeShippingResponse of storeShippingResponses) {
              const location = this.locations.find( loc => loc.location_id === pickupLocationIds[i])
              if(storeShippingResponse.status === 'fulfilled') {
                const deliveryPromises =  storeShippingResponse.value.delivery_promises
                for (let deliveryPromise of deliveryPromises) {
                  deliveryPromise.pickup_location = location
                  if (deliveryPromise.delivery_method === 'click_and_collect') {
                    deliveryPromise.carbonFootprint = location.distance * 0.2
                  } else{
                    const carbonFootPrint = await this.getCarbonFootprint(location.address.latitude, location.address.longitude, items.length)
                    deliveryPromise.carbonFootprint = carbonFootPrint + location.distance * 0.2
                  }
                  storeDeliveries.push(deliveryPromise)
                }
              } else {
                 storeDeliveries.push({
                   pickup_location: location,
                   error: 'unavailable'
                })
              }
              i ++
            }
            this.$set(results, 'storeDeliveries', storeDeliveries)

            this.promisesDeliveryLoading = true
            this.loading = false
          }
      })
    },
    async getLocations() {
      let myInit = this.buildMyInit()
      try {
        const res = await this.$Amplify.API.get("OmsApiUrl", "/location/v1/int/locations?sortBy=location_id&itemsPerPage=10000&filters=%7B%22type%22%3A%5B%22shop%22%5D%7D", myInit)
        this.locations = res.data
      } catch (e) {
        this.loading = false
        EventBus.$emit('snackbar', {
          display: true,
          text: "error during locations loading",
          color: "error"
        })
      }
    },
    async getCarbonFootprint(lat, lng, quantity) {
      const request = {
        headers: {
          'x-api-key': process.env.VUE_APP_CARBON_FOOT_PRINT_API_KEY
        },
        body: {
          "start_address": {
            "latitude": lat,
            "longitude": lng
          },
          "end_address": {
            "latitude": 45.6430728,
            "longitude": 3.885179
          },
          "weight": 0.5 * quantity
        }
      }
      try {
        const res = await this.$Amplify.API.post('CarbonFootPrintApi', '/carbon-footprint', request)
        return res.carbonFootPrint
      } catch (e) {
        EventBus.$emit('snackbar', {
          display: true,
          text: "error during carbon footprint estimation",
          color: "error"
        })
        return null
      }
    }
  }
}
</script>

<style>

.v-subheader-class {
  padding: 0px !important;
}

.v-chip {
  margin: 3px;
}

div.v-toolbar__content {
  padding: 14px 16px;
}
</style>