<template>
  <v-container id="location-details" fluid>
    <v-expansion-panels
        v-model="panel"
    >
      <v-expansion-panel eager>
        <v-expansion-panel-header>
          <p>{{ i18n.promisesDelivery.results }} {{ i18n.promisesDelivery.at }} {{deliveryPromises.date.format('HH:mm:ss')}}</p>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-row class="align-start">
            <v-col cols="12" md="6" lg="12" v-if="deliveryPromises.homeDeliveries || deliveryPromises.relayPointDeliveries">
              <v-card outlined v-if="deliveryPromises.homeDeliveries">
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>$vuetify.icons.delivery</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content v-if="deliveryPromises.homeDeliveries.error">
                      <div class="overline mb-4">
                        {{ i18n.promisesDelivery.homeDelivery }}
                      </div>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <div class="overline mb-4">
                        {{ i18n.promisesDelivery.homeDelivery }} - {{deliveryPromises.deliveryAddress.street_1}} {{deliveryPromises.deliveryAddress.postal_code}} {{deliveryPromises.deliveryAddress.city}}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-container fluid>
                    <div class="delivery-error pl-3" v-if="deliveryPromises.homeDeliveries.error">
                      {{ i18n.promisesDelivery.errors.unavailable }}
                    </div>
                    <v-card v-else v-for="delivery, n in deliveryPromises.homeDeliveries" :key="n" outlined class="pb-1 pt-1 mb-3">
                      <v-row style="width: 100%" class="justify-space-between">
                        <v-col cols="auto" align-self="start">
                            <div v-if="delivery.carbonFootprint" style="display: flex" class="pr-1 pl-3 pb-1 pt-4">
                              <h5 class="pr-0">{{ delivery.delivery_method }}</h5>
                              <div class="pr-1 pl-3 pb-1"> <v-img :src="require('../assets/green.png')" width="15px" height="19px" ></v-img></div>
                              <h5 class="pr-1 pl-1 pb-1 font-weight-regular"> {{ delivery.carbonFootprint.toFixed(1) }}kg/CO2</h5>
                            </div>
                        </v-col>
                        <v-col cols="auto" align-self="end">
                          <v-card-text class="pr-0 pl-3">{{ getDeliveryDateLabel(delivery.earliest_delivery_date) }} - <span class="font-weight-bold">{{ getDeliveryCostLabel(delivery.cost, delivery.currency) }}</span></v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-container>
                </v-list>
              </v-card>
              <!-- <v-card outlined v-if="deliveryPromises.relayPointDeliveries" style="margin-top: 10px">
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>$vuetify.icons.delivery</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="overline mb-4">
                        {{ i18n.promisesDelivery.relayPointDelivery }} - {{deliveryPromises.deliveryAddress.postal_code}} {{deliveryPromises.deliveryAddress.city}}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-container fluid>
                    <div class="delivery-error pl-3" v-if="deliveryPromises.relayPointDeliveries.error">
                      {{ i18n.promisesDelivery.errors.unavailable }}
                    </div>
                    <v-card v-else v-for="delivery, n in deliveryPromises.relayPointDeliveries" :key="n" outlined class="pb-1 pt-1 mb-3">
                      <v-row style="width: 100%" class="justify-space-between">
                        <v-col cols="auto" align-self="start">
                            <div v-if="delivery.carbonFootprint" style="display: flex" class="pr-1 pl-3 pb-1 pt-4" >
                              <h5 class="pr-0">{{ i18n.promisesDelivery.relay_point_of }} {{deliveryPromises.deliveryAddress.city}} </h5>
                              <div class="pr-1 pl-3 pb-1"> <v-img :src="require('../assets/green.png')" width="15px" height="19px" ></v-img></div>
                              <h5 class="pr-1 pl-1 pb-1 font-weight-regular"> {{ delivery.carbonFootprint.toFixed(1) }}kg/CO2</h5>
                            </div>
                        </v-col>
                        <v-col cols="auto" align-self="end">
                          <v-card-text class="pr-0 pl-3">{{ getDeliveryDateLabel(delivery.earliest_delivery_date) }} - <span class="font-weight-bold">{{ getDeliveryCostLabel(delivery.cost, delivery.currency) }}</span></v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-container>
                </v-list>
              </v-card> -->
            </v-col>
            <v-col cols="12" md="6" lg="12" v-if="deliveryPromises.storeDeliveries">
              <v-card outlined>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>$vuetify.icons.locations</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="overline mb-4">
                        {{ i18n.promisesDelivery.storeDelivery }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <v-container fluid v-if="deliveryPromises.storeDeliveries.length">
                    <v-card v-for="delivery, n in deliveryPromises.storeDeliveries" :key="n" outlined class="pb-1 pt-1 mb-3">
                      <v-row align="center" style="width: 100%" class="justify-space-between">
                        <v-col cols="auto" align-self="start">
                          <v-card-text class="pr-1 pl-3 pb-1 pt-1">{{ delivery.pickup_location.address.city }}</v-card-text>
                          <div v-if="delivery.carbonFootprint != undefined" style="display: flex" class="pr-1 pl-3 pb-1 pt-1">
                            <h5 class="pr-0">{{ delivery.pickup_location.name }}</h5>
                            <div class="pr-1 pl-3 pb-1"> <v-img :src="require('../assets/green.png')" width="15px" height="19px" ></v-img></div>
                            <h5 class="pr-1 pl-1 pb-1 font-weight-regular"> {{ delivery.carbonFootprint.toFixed(1) }}kg/CO2</h5>
                            <h5 class="pr-0 pl-3 font-weight-regular">{{ delivery.pickup_location.distance.toFixed(2) }}km</h5>
                          </div>
                          <div v-else style="display: flex" class="pr-1 pb-1 pt-1">
                            <h5 class="pr-0 pl-3">{{ delivery.pickup_location.name }}</h5>
                            <h5 class="pr-0 pl-3 font-weight-regular">{{ delivery.pickup_location.distance.toFixed(2) }}km</h5>
                          </div>
                        </v-col>
                        <v-col v-if="delivery.error" cols="auto" align-self="end">
                          <v-card-text class="pr-0 pl-3 delivery-error">
                            {{ i18n.promisesDelivery.errors[delivery.error]}}
                          </v-card-text>
                        </v-col>
                        <v-col v-else cols="auto" align-self="end">
                          <v-card-text class="pr-0 pl-3">
                            {{ getPickupDateLabel(delivery.earliest_delivery_date) }} -  <span class="font-weight-bold">{{ getDeliveryCostLabel(delivery.cost, delivery.currency) }}</span>
                          </v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-container>
                  <v-container v-else>
                    <div class="delivery-error">
                      {{ i18n.promisesDelivery.errors.unavailable }}
                    </div>
                  </v-container>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>

import { Utils } from '@/mixins/Utils';
import  moment  from 'moment'
import EventBus from "@/event-bus";
const i18n = require('../lang')

export default {
  name: "promisesDelivery",
  mixins: [Utils],
  components: {
    
  },
  props: {
    deliveryPromises: Object,
    openPanel: Boolean
  },
  data() {
    return {
      panel: this.openPanel ? 0 : null,
      i18n: i18n(this.$i18n.locale),
    };
  },
  computed: {
    deliveryPromisesComputed() {
      console.log('tamère')
      return this.deliveryPromises
    }
  },
  mounted() {
    EventBus.$on('closePanels', ()=> this.panel = null)
  },
  methods: {
    getDeliveryCostLabel(cost, currency) {
      if (cost === 0) {
        return this.i18n.promisesDelivery.free
      }
      return  `${cost} ${currency}`
    },
    getDeliveryDateLabel(date) {
      return `${this.i18n.promisesDelivery.deliveredOn} ${moment(date).format('D MMMM')}`
    },
    getPickupDateLabel(date) {
      const hours = this.getDeliveryHours(date)
      if(hours <= 6) {
        return  `${this.i18n.promisesDelivery.pickupAvailable} ${hours}h`
      }
      return `${this.i18n.promisesDelivery.pickupAvailableThe} ${moment(date).format('D MMMM')} ${this.i18n.promisesDelivery.fromDate } ${moment(date).format('HH:mm')}`
    },
    getDeliveryHours(date) {
      const now = moment(new Date());
      const end = moment(date);
      const duration = moment.duration(end.diff(now));
      return Math.ceil(duration.asHours());
    }
  }
}
</script>

<style>

.v-subheader-class {
  padding: 0px !important;
}

.v-chip {
  margin: 3px;
}

div.v-toolbar__content {
  padding: 14px 16px;
}

.delivery-error {
  color: red;
  font-size: 14px;
}
</style>